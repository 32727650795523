import React from 'react';
import img01 from '../../../../upload/banner_service_other.jpg';
import img02 from '../../upload/business-people-and-architects-working-on-project-K7869CQ.jpg';
import img03 from '../../upload/business-man-and-woman-meeting-at-work-in-office-QX9VMGL.jpg';

import ImgComponent from '../imgComponent';

const Banner = () => {
	return (
		<section
			className="elementor-element elementor-element-fb7667e elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
			data-element_type="section"
			data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;shape_divider_bottom&quot;:&quot;curve&quot;,&quot;shape_divider_bottom_negative&quot;:&quot;yes&quot;}"
		>
			<div className="elementor-shape elementor-shape-bottom" data-negative="true">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
					<path
						className="elementor-shape-fill"
						d="M500,97C126.7,96.3,0.8,19.8,0,0v100l1000,0V1C1000,19.4,873.3,97.8,500,97z"
					/>
				</svg>
			</div>
			<div className="elementor-container elementor-column-gap-default">
				<div className="elementor-row">
					<div
						className="elementor-element elementor-element-badcbdc elementor-column elementor-col-100 elementor-top-column"
						data-element_type="column"
					>
						<div className="elementor-column-wrap elementor-element-populated">
							<div className="elementor-widget-wrap">
								<div
									className="elementor-element elementor-element-7bef6e7 elementor-widget elementor-widget-avante-slider-parallax"
									data-element_type="widget"
									data-widget_type="avante-slider-parallax.default"
								>
									<div className="elementor-widget-container">
										<div
											className="slider-parallax-wrapper"
											data-autoplay={8000}
											data-pagination={0}
											data-navigation={1}
										>
											<div className="slider_parallax_inner">
												<div className="slider_parallax_slides">
													<div className="slide is-active ">
														<div className="slide-content align-center">
															<div className="standard-wrapper">
																<div className="caption">
																	<div className="title title-banner">
																		<h2>Other Services</h2>
																	</div>
																	<div className="text">
																		Other beneficial services proviced by our
																		company for you
																	</div>
																	<a className="button" id="warnaButton" href="/HelpCenter/ContactUs">
																		Get in touch{' '}
																	</a>
																</div>
															</div>
														</div>
														<div className="image-container">
															<ImgComponent
																pmlImg="banner_service_ot.jpg"
																className="image"
															/>
															{/* <img
																// ** //
																src={img01}
																alt=""
																className="image"
															/> */}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Banner;
