import React from 'react';
import './cssberhasil';
import LoadingLogo from '../../upload/loading_logo.png';
// import Header from './HeaderSub';
// import Navbar from './NavbarSub';
import Navbar from './NavbarSub';
import Header from './HeaderSub';
import Footer from '../layout/Footer';
import Banner from '../content/ServicesOthers/Banner';
import Content from '../content/ServicesOthers/Content';
import TitleServices from '../content/ServicesOthers/TitleServices';
import PhotoServices from '../content/ServicesOthers/PhotoServices';
import ContactBus from '../content/ContactBus';
import Mobile from '../content/Mobile';
import SEO from '../SEO';

import Helmet from 'react-helmet';
import JSberhasil from './jsberhasil';


import Loadable from 'react-loadable';

function Loading(props) {
	if (props.error) {
		return (
			<div>
				Error! <button onClick={props.retry}>Retry</button>
			</div>
		);
	} else if (props.pastDelay) {
		return <div>Loading...</div>;
	} else {
		return null;
	}
}

let ProductTable = Loadable({
	loader: () => import('../content/ServicesOthers/ProductTable'),
	loading: Loading
});


const LayoutServicesOT = () => {
	return (
		// <>
		<React.Fragment>
			<Helmet
				bodyAttributes={{
					class:
						'home page-template-default page page-id-4074 theme-avante woocommerce-no-js menu-transparent lightbox-black leftalign footer-reveal elementor-default elementor-page elementor-page-4074'
				}}
			/>
			<SEO title="Pharmametrics Lab | Other Clinical Outsource Services" description="Pharmametrics Lab as CRO in Indonesia provide other beneficial services ranging from regulatory submission, study report writing, and etc" />
			{/*
			<div id="loftloader-wrapper" className="pl-imgloading" data-show-close-time="15000">
                <div className="loader-inner">
                    <div id="loader">
                        <div className="imgloading-container">
                            <span style= {{backgroundImage: `url(${LoadingLogo})`}} ></span>
                        </div>
                        <img alt="loader image" src={LoadingLogo} />
                    </div>
                </div>
                <div className="loader-section section-fade">
                </div>
                <div className="loader-close-button">
                    <span className="screen-reader-text">Close</span>
                </div>
			</div>
			*/}

			<div id="perspective">
				<Mobile />
				<div id="wrapper" className="hasbg transparent" style={{ paddingTop: '0px' }}>
					<div id="elementor-header" className="main-menu-wrapper">
						<div
							data-elementor-type="wp-post"
							data-elementor-id="4287"
							className="elementor elementor-4287"
							data-elementor-settings="[]"
						>
							<div className="elementor-inner">
								<div className="elementor-section-wrap">
									<Header />
									<Navbar />
								</div>
							</div>
						</div>
					</div>
					<div id="elementor-sticky-header" className="main-menu-wrapper">
						<div
							data-elementor-type="wp-post"
							data-elementor-id="4287"
							className="elementor elementor-4287"
							data-elementor-settings="[]"
						>
							<div className="elementor-inner">
								<div className="elementor-section-wrap">
									<Navbar />
								</div>
							</div>
						</div>
					</div>

					<div id="page-content-wrapper" className="">
						<div className="inner">
							<div className="inner-wrapper">
								<div className="sidebar-content fullwidth">
									<div
										data-elementor-type="wp-page"
										data-elementor-id="5459"
										className="elementor elementor-5459"
										data-elementor-settings="[]"
									>
										<div className="elementor-inner">
											<div className="elementor-section-wrap">
												<Banner />
												<Content />
												<ProductTable/>
												<TitleServices />
												<PhotoServices />
												<ContactBus title='Interested with our Other Service ?' />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
			<JSberhasil />
			{/* </> */}
		</React.Fragment>
	);
};

export default LayoutServicesOT;
