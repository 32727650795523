import React from 'react';

import img01 from '../../../../upload/thumbnail_service_babe.png';
import img02 from '../../../../upload/thumbnail_service_CT.jpg';
import img03 from '../../../../upload/thumbnail_service_CA.png';

const PhotoServices = () => {
	return (
		<section
			className="elementor-element elementor-element-65b0113 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
			data-id="65b0113"
			data-element_type="section"
		>
			<div className="elementor-container elementor-column-gap-default">
				<div className="elementor-row">
					<div
						className="elementor-element elementor-element-eb81c42 elementor-column elementor-col-100 elementor-top-column"
						data-id="eb81c42"
						data-element_type="column"
						data-settings="{&quot;&quot;:&quot;false&quot;,&quot;&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
					>
						<div className="elementor-column-wrap elementor-element-populated service-left">
							<div className="elementor-widget-wrap">
								<div
									className="elementor-element elementor-element-d80e7ca elementor-widget elementor-widget-avante-portfolio-classic"
									data-id="d80e7ca"
									data-element_type="widget"
									data-settings="{&quot;&quot;:&quot;false&quot;,&quot;&quot;:&quot;false&quot;,&quot;avante_ext_is_parallax_mouse&quot;:&quot;false&quot;,&quot;avante_ext_is_infinite&quot;:&quot;false&quot;}"
									data-widget_type="avante-portfolio-classic.default"
								>
									<div className="elementor-widget-container service-width">
										<div className="portfolio-classic-container">
											<div
												className="portfolio-classic-content-wrapper portfolio-classic layout-avante-three-cols "
												data-cols={3}
											>
												<div
													className="portfolio-classic-grid-wrapper avante-three-cols portfolio-1 tile scale-anm technology all no_filter emir disable_tablet"
													data-delay={150}
													data-minwidth={769}
													data-move-y="45px"
												>
													<div className="portfolio-classic-img">
														<img src={img01} alt="" />
														<div>
															<span className="ti-arrow-right" />
															<div className="curl" />
															<a href="/Services/BABEStudies" />
														</div>
													</div>
													<br className="clear" />
													<div className="portfolio-classic-content">
														<h3 className="portfolio-classic_title service-font-size">
															<a href="/Services/BABEStudies" style={{ color: '#00623d' }}>
																Bioavailability & Bioequivalence (BA/BE) Studies
															</a>
														</h3>
														<div className="portfolio-classic-subtitle" />
													</div>
												</div>
												<div
													className="portfolio-classic-grid-wrapper avante-three-cols portfolio-2 tile scale-anm financial retail all no_filter emir disable_tablet"
													data-delay={300}
													data-minwidth={769}
													data-move-y="45px"
												>
													<div className="portfolio-classic-img">
														<img src={img02} alt="" />
														<div>
															<span className="ti-arrow-right" />
															<div className="curl" />
															<a href="/Services/ClinicalTrials" />
														</div>
													</div>
													<br className="clear" />
													<div className="portfolio-classic-content">
														<h3 className="portfolio-classic_title service-font-size">
															<a href="/Services/ClinicalTrials" style={{ color: '#00623d' }}>Clinical Trials</a>
														</h3>
														<div className="portfolio-classic-subtitle" />
													</div>
												</div>
												<div
													className="portfolio-classic-grid-wrapper avante-three-cols last portfolio-3 tile scale-anm retail all no_filter emir disable_tablet"
													data-delay={450}
													data-minwidth={769}
													data-move-y="45px"
												>
													<div className="portfolio-classic-img">
														<img src={img03} alt="" />
														<div>
															<span className="ti-arrow-right" />
															<div className="curl" />
															<a href="/Services/ContractAnalysis" />
														</div>
													</div>
													<br className="clear" />
													<div className="portfolio-classic-content">
														<h3 className="portfolio-classic_title service-font-size">
															<a href="/Services/ContractAnalysis" style={{ color: '#00623d' }}>Contract Analysis</a>
														</h3>
														<div className="portfolio-classic-subtitle" />
													</div>
												</div>
												<br className="clear" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default PhotoServices;
